<template>
    <div class="layout-modal" :key="url" id="modal_view" data-get="">
        <loader-modal v-if="flagModalLoader"/>
        <modal-slider v-if="activeModalSlider" :data-slider="dataSlider" @closeModalSlider="closeModalSliderParent"/>
        <transition name="modal-danone">
            <div class="report-form" ref="modalElement" v-show="!flagModalLoader && flagModalTsk">
                <div class="report-form__container">
                    <div class="report-form__container-close" @click="closeModal">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle opacity="0.6" cx="12" cy="12" r="12" fill="#FF0C3E"/>
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                  d="M17.4 7.8C17.7314 7.46863 17.7314 6.93137 17.4 6.6C17.0686 6.26863 16.5314 6.26863 16.2 6.6L12 10.8L7.8 6.6C7.46863 6.26863 6.93137 6.26863 6.6 6.6C6.26863 6.93137 6.26863 7.46863 6.6 7.8L10.8 12L6.6 16.2C6.26863 16.5314 6.26863 17.0686 6.6 17.4C6.93137 17.7314 7.46863 17.7314 7.8 17.4L12 13.2L16.2 17.4C16.5314 17.7314 17.0686 17.7314 17.4 17.4C17.7314 17.0686 17.7314 16.5314 17.4 16.2L13.2 12L17.4 7.8Z"
                                  fill="white"/>
                        </svg>
                    </div>
                    <div class="report-form__container-task">
                        <div class="report-form__container-task__title">
                            <span>Задача:</span>
                        </div>
                        <div class="report-form__container-task__description">
                            <div class="report-form__container-task__description-desc"
                                 v-if="dataReportForm.task && dataReportForm.task.description">
                                <span>{{ dataReportForm.task.description }}</span>
                            </div>
                            <div class="report-form__container-task__description-status"
                                 v-if="dataReportForm.task && dataReportForm.task.status">
                                <span><i>Статус:</i>{{ dataReportForm.task.status }}</span>
                                <span><i>Дата постановки: </i> {{ replaceDate(dataReportForm.task.dateStaging) }}</span>
                                <span><i>Дата выполнения: </i> {{ replaceDate(dataReportForm.task.dateFulfillment) }}</span>
                                <span><i>Дата завершения: </i> {{ replaceDate(dataReportForm.task.dateCompletion) }}</span>
                                <span><i>Оценочная форма: </i> {{ dataReportForm.task.valuationForm || ''}}</span>
                            </div>
                        </div>
                        <div class="report-form__container-task__author">
                            <span><i>Авторы задачи: </i> {{ dataReportForm.task.author || '' }}</span>
                            <span v-if="isExternalUser"><i>Исполнитель задачи: </i> {{ dataReportForm.task.executorTask || ''}}</span>
                            <span><i>{{ isExternalUser ? "Маршрут" : "Исполнитель задачи"}}: </i> {{ !isExternalUser ? dataReportForm.task.executorTask : dataReportForm.task.taskRoute || ''}}</span>
                            <span v-if="dataReportForm.task.place"><i>Торговая точка: </i> {{ dataReportForm.task.place || ''}}</span>
                            <span><i>Подразделение 1 уровня исполнителя: </i> {{ dataReportForm.task.levelOneUnit || '' }}</span>
                            <span><i>Подразделение испольнителя: </i> {{ dataReportForm.task.executiveDivision || ''}}</span>
                        </div>
                    </div>
                    <div class="report-form__container-comment">
                        <div class="report-form__container-comment__title">
                            <span>Комментарии:</span>
                        </div>
                        <div class="report-form__container-comment__body">
                            <div>
                                <comment from="автор" :data-comment="dataReportForm.comment.author || []"/>
                            </div>
                            <div>
                                <comment from="исполнитель" :data-comment="dataReportForm.comment.executor || []"/>
                            </div>
                        </div>
                    </div>
                    <div class="report-form__container-snapshots">
                        <div class="report-form__container-snapshots__title">
                            <span>Снимки</span>
                        </div>
                        <div class="report-form__container-snapshots__body">
                            <div class="report-form__container-snapshots__body-author">
                                <div class="report-form__container-snapshots__body-author-title"><span>автор</span>
                                </div>
                                <div class="report-form__container-snapshots__body-author-body all-snapshots">
                                    <card-photo v-for="(card, i) in dataReportForm.snapshots.author"
                                                type-card="author"
                                                :key="i"
                                                :data-card="card" @openPhoto="openPhotoParent"/>
                                </div>
                            </div>
                            <div class="report-form__container-snapshots__body-executor">
                                <div class="report-form__container-snapshots__body-executor-title">
                                    <span>Исполнитель</span></div>
                                <div class="report-form__container-snapshots__body-executor-body all-snapshots">
                                    <card-photo v-for="(card, i) in dataReportForm.snapshots.executor"
                                                type-card="executor"
                                                :key="i"
                                                :data-card="card" @openPhoto="openPhotoParent"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>

    import Axios from 'axios'
    import CardPhoto from "./cardPhoto/cardPhoto";
    import Comment from "./comment/comment";
    import ModalSlider from "./modalSlider/modalSlider";
    import LoaderModal from "./loader/loaderModal";

    export default {
        name: "hmModalTask",
        components: {LoaderModal, ModalSlider, Comment, CardPhoto},
        props: {
            label: {
                type: String,
                default: 'кнопка редактирования'
            },
            url: {
                type: String,
                default: ''
            },
        },
        data() {
            return {
                flagModalTsk: false,
                flagModalLoader: false, // флаг отвечает за загрузку пока грузиться модалка
                // флаг модалкит просмотра фоток
                flagModalSlider: false,
                dataReportForm: {
                    task: {},
                    comment: {},
                    snapshots: {
                        executor: [],
                        author: []
                    }
                },
                // информация по слайдеру
                dataSlider: {
                    page: null, // сколько всего картинок
                    activePage: null, // активная картинка
                    arrayUrl: null, //массив картинок
                    from: null // в каком блоке
                },
                urlId: null,
            }
        },
        computed: {
          activeModalSlider() {
              return !this.flagModalTsk && this.flagModalSlider && !this.flagModalLoader
          },
          isExternalUser(){
              return this.dataReportForm.task.isExternalUser;
          }
        },
        watch: {
            urlId(data) {
                if(!this.urlId) {
                    this.eventGetId()
                } else {
                    this.flagModalLoader = true;
                    this.flagModalTsk = false;
                    this.flagModalSlider = false;
                    this.getData(`/fc/ajax/get-task/task_id/${this.urlId}`);
                }
            }
        },
        methods: {
            eventGetId() {
                if(document.getElementById('modal_view') && document.getElementById('modal_view').dataset && document.getElementById('modal_view').dataset.get && document.getElementById('modal_view').dataset.get !== '') {
                    this.urlId = document.getElementById('modal_view').dataset.get;
                } else {
                    setTimeout(() => {
                        this.eventGetId()
                    }, 1000)
                }
            },
            /**
             * кастыльный метод по добавлению модалки поверх всего
             * @param elClass - элемент с которым работаю
             */
            openModal(elClass) {
                if (elClass === 'report-form') {
                    this.flagModalTsk = true;
                    this.flagModalLoader = true;
                    this.flagModalSlider = false;
                } else if (elClass === 'modal-slider') {
                    this.flagModalTsk = false;
                    this.flagModalSlider = true;
                    this.flagModalLoader = false;
                }
            },
            // метод закрытия модлки информационной, отправка родителю
            closeModal() {
                this.flagModalTsk = false;
                document.getElementById('modal_view').dataset.get = '';
                this.urlId = null;
                document.getElementById('modal_view').style.display = 'none';
                // this.$emit('modalClose')
            },
            openPhotoParent(data) {
                this.dataSlider.from = data.type;
                this.dataSlider.page = this.dataReportForm.snapshots[data.type].length - 1;
                this.dataSlider.arrayUrl = this.dataReportForm.snapshots[data.type].slice().map(el => el.url);
                this.dataReportForm.snapshots[data.type].forEach((el, i) => el.url === data.url ? this.dataSlider.activePage = i : '');
                this.openModal('modal-slider');

            },
            // обработка фоток
            closeModalSliderParent() {
                this.flagModalTsk = true;
                this.flagModalSlider = false;
            },
            exitKey(e) {
                if (e.key === 'Escape') this.closeModal()
            },
            // функция получения данных
            async getData(idUrl) {
                // перед открытием очистка всех полей
                this.dataReportForm = {
                    task: {},
                    comment: {},
                    snapshots: {
                        executor: [],
                            author: []
                    }
                };
                await Axios.get(idUrl)
                    .then(res => {
                        console.log(res);
                        for (let i in res.data.images) {
                            if (res.data.images[i]['by_supervisor'] !== '1') {
                                this.dataReportForm.snapshots.executor.push(res.data.images[i])
                            } else if (res.data.images[i]['by_supervisor'] === '1') {
                                this.dataReportForm.snapshots.author.push(res.data.images[i])
                            }
                        }
                        const isExternalUser = !!(+res.data.isExternalUser);

                        this.dataReportForm.task = {
                            description: res.data.name,
                            status: res.data.state,
                            dateStaging: res.data.date_event,
                            dateFulfillment: res.data.date,
                            dateCompletion: res.data.date_final,
                            valuationForm: res.data.competence,
                            place: res.data.place,
                            author: res.data.superFio, // авторы задачи
                            executorTask: !isExternalUser ? res.data.userFio : res.data.localAdminFio , //исполнитель задачи
                            taskRoute: isExternalUser ? res.data.userFio : '' , //исполнитель задачи
                            levelOneUnit: res.data.topDepartment, // подразделение 1гшо уровня
                            executiveDivision: res.data.department,//Подразделение испольнителя
                            isExternalUser
                        };
                        this.dataReportForm.comment = {
                            author: res.data.comment_supervisor,
                            executor: res.data.comment_user
                        };
                        this.flagModalTsk = true;
                        this.flagModalSlider = false;
                        this.flagModalLoader = false;
                    })
                    .catch(err => {
                        this.flagModalLoader = false;
                        console.log(err)
                    })
            },
            // для оберазания даты
            replaceDate(str) {
                if(str && str.split('-').length >= 2 && str.search(/ /) === -1) {
                    let x = str.split('-');
                    return x[2] + '.' + x[1] + '.' + x[0]
                } else if(str && str.search(/ /) !== -1) {
                    let x = str.split(' ')[0].split('-');
                    return x[2] + '.' + x[1] + '.' + x[0]
                }
                return str || ''
            }

        },
        mounted() {
            document.addEventListener('keydown', this.exitKey, false);
            this.eventGetId();
            // this.openModal('report-form');
        },
        destroyed() {
            document.removeEventListener('keydown', this.exitKey, false);
        }
    }
</script>

<style lang="scss">
    #modal_edit {
        display: none;
    }
    .layout-modal {
        > span {
            font-size: 14px;
            color: #1a83c4;
            cursor: pointer;
        }
    }

    .report-form {
        width: 100%;
        height: 100%;
        position: fixed;
        top: 0;
        left: 0;
        background: rgba(0, 0, 0, 0.3);
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 9999999999999999999;

        &__container {
            width: 80%;
            height: 90%;
            background: #FFFFFF;
            border-radius: 2px;
            position: relative;
            padding: 34px 34px 26px 34px;
            box-sizing: border-box;
            overflow: auto;

            &::-webkit-scrollbar {
                width: 6px;
            }

            &::-webkit-scrollbar-track {
                -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
                border-radius: 10px;
            }

            &::-webkit-scrollbar-thumb {
                border-radius: 10px;
                background-color: #979797;
                -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);

                &:hover {
                    background-color: #8f8597;
                }
            }

            &-close {
                position: fixed;
                right: 16em;
                top: 66px;
                width: 24px;
                height: 24px;
                cursor: pointer;
                z-index: 999999;
            }

            &-task {
                width: 100%;

                &__title {
                    width: 100%;
                    height: 45px;
                    display: flex;
                    justify-content: flex-start;
                    align-items: flex-start;
                    border-bottom: 1px solid #E1DDDD;

                    > span {
                        font-family: Ubuntu;
                        font-style: normal;
                        font-weight: bold;
                        font-size: 24px;
                        line-height: 28px;
                        letter-spacing: 0.3px;
                        color: #000000;
                    }
                }

                &__description {
                    width: 100%;
                    height: 208px;
                    display: flex;
                    flex-wrap: nowrap;
                    margin-top: 19px;

                    > div {
                        width: 50%;
                    }

                    &-desc {
                        height: 100%;
                        display: flex;
                        padding: 16px 21px 16px 16px;
                        background: #FAFAFA;
                        border: 1px solid #E0E0E0;
                        box-sizing: border-box;
                        border-radius: 2px;
                        overflow: auto;

                        &::-webkit-scrollbar {
                            width: 6px;
                        }

                        &::-webkit-scrollbar-track {
                            -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
                            border-radius: 10px;
                        }

                        &::-webkit-scrollbar-thumb {
                            border-radius: 10px;
                            background-color: #979797;
                            -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);

                            &:hover {
                                background-color: #8f8597;
                            }
                        }

                        > span {
                            font-family: Ubuntu;
                            font-style: normal;
                            font-weight: normal;
                            font-size: 14px;
                            line-height: 16px;
                            letter-spacing: 0.3px;
                            color: #000000;
                        }
                    }

                    &-status {
                        display: flex;
                        flex-direction: column;
                        padding-left: 115px;
                        box-sizing: border-box;

                        > span {
                            font-family: Ubuntu;
                            font-style: normal;
                            font-weight: normal;
                            font-size: 14px;
                            line-height: 16px;
                            letter-spacing: 0.3px;
                            color: #000000;

                            > i {
                                text-transform: none;
                                font-style: normal;
                                margin-right: 2px;
                                text-decoration: none;
                                font-weight: 700;
                            }

                            &:not(:last-child) {
                                margin-bottom: 32px;
                            }
                        }
                    }
                }

                &__author {
                    width: 50%;
                    min-height: 200px;
                    margin-top: 29px;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: flex-start;

                    > span {
                        font-family: Ubuntu;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 14px;
                        line-height: 16px;
                        letter-spacing: 0.3px;
                        color: #000000;

                        > i {
                            text-decoration: none;
                            font-weight: 700;
                            text-transform: none;
                            font-style: normal;
                            margin-right: 2px;
                        }

                        &:not(:last-child) {
                            margin-bottom: 32px;
                        }
                    }
                }
            }

            &-comment {
                width: 100%;

                &__title {
                    width: 100%;
                    height: 45px;
                    display: flex;
                    justify-content: flex-start;
                    align-items: flex-start;
                    border-bottom: 1px solid #E1DDDD;

                    > span {
                        font-family: Ubuntu;
                        font-style: normal;
                        font-weight: bold;
                        font-size: 24px;
                        line-height: 28px;
                        letter-spacing: 0.3px;
                        color: #000000;
                    }
                }

                &__body {
                    width: 100%;
                    display: flex;
                    flex-wrap: nowrap;

                    > div:nth-child(1) {
                        width: 698px;
                        margin-right: 112px;
                    }

                    > div:nth-child(2) {
                        width: 398px;
                    }
                }
            }

            &-snapshots {
                width: 100%;
                margin-top: 39px;

                &__title {
                    width: 100%;
                    height: 45px;
                    display: flex;
                    justify-content: flex-start;
                    align-items: flex-start;
                    border-bottom: 1px solid #E1DDDD;

                    > span {
                        font-family: Ubuntu;
                        font-style: normal;
                        font-weight: bold;
                        font-size: 24px;
                        line-height: 28px;
                        letter-spacing: 0.3px;
                        color: #000000;
                    }
                }

                &__body {
                    display: flex;
                    margin-top: 16px;

                    &-author {
                        width: 698px;
                        margin-right: 112px;

                        &-body {
                            > div:nth-child(2n + 1) {
                                margin-right: 64px;
                            }
                        }
                    }

                    &-executor {
                        width: 398px;

                        &-body {

                        }
                    }

                    &-author, &-executor {
                        &-title {
                            > span {
                                font-family: Ubuntu;
                                font-style: normal;
                                font-weight: 600;
                                font-size: 18px;
                                line-height: 21px;
                                letter-spacing: 0.3px;
                                color: #000000;
                                text-transform: capitalize;
                            }
                        }

                        &-body {
                            display: flex;
                            flex-wrap: wrap;
                            margin-top: 16px;

                            > div {
                                margin-bottom: 42px;
                            }
                        }
                    }
                }
            }
        }
    }


    .modal-danone-enter-active {
        transition: all .1s ease;
    }

    .modal-danone-leave-active {
        transition: all .1s cubic-bezier(1.0, 0.5, 0.8, 1.0);
    }

    .modal-danone-enter, .modal-danone-leave-to {
        transform: scale(0.7);
        opacity: 0;
    }


    @media (max-width: 1604px) {
        .report-form {
            &__container {
                &-comment {
                    &__body {
                        > div:nth-child(1) {
                            width: 580px;
                        }
                    }
                }

                &-snapshots {
                    &__body {
                        &-author {
                            margin-right: 6px;

                            &-body {
                                > div:nth-child(2n + 1) {
                                    margin-right: 12px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    @media (max-width: 1367px) {
        .report-form {
            &__container {
                padding: 34px 16px;

                &-task {
                    &__description {
                        justify-content: space-between;

                        > div {
                            width: calc(50% - 27px);
                        }

                        &-status {
                            padding-left: 0;
                        }
                    }

                    &__author {
                        height: auto;
                        min-height: auto;
                        margin-top: 20px;
                        margin-bottom: 28px;

                        > span:not(:last-child) {
                            margin-bottom: 16px;
                        }
                    }
                }

                &-comment {
                    &__body {
                        justify-content: space-between;

                        > div {
                            width: calc(50% - 15.5px) !important;
                        }

                        > div:nth-child(1) {
                            margin-right: 0 !important;
                        }
                    }
                }

                &-snapshots {
                    &__body {
                        > div {
                            width: 50%;

                            .all-snapshots {
                                > div {
                                    width: 328px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    @media (max-width: 916px) {
        .report-form {
            &__container {
                width: 100%;
                height: 100%;
                &-close {
                    right: 2em !important;
                    top: 2em !important;
                }

                &-task {
                    &__description {
                        height: auto;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;

                        &-desc {
                            height: auto;
                            width: 100% !important;
                            padding: 12px !important;
                            margin-bottom: 24px;
                        }

                        &-status {
                            width: 100% !important;

                            > span:not(:last-child) {
                                margin-bottom: 16px;
                            }
                        }

                        &-author {
                            width: 100% !important;
                        }
                    }
                }

                &-comment {
                    &__body {
                        justify-content: center;
                        align-items: center;
                        flex-direction: column;

                        > div {
                            width: 100% !important;
                        }
                    }
                }

                &-snapshots {
                    &__body {
                        justify-content: center;
                        align-items: center;
                        flex-direction: column;

                        > div {
                            width: 100%;

                            .all-snapshots {
                                justify-content: center;

                                > div {
                                    min-width: 328px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    @media (max-width: 500px) {
        .report-form {
            &__container {
                &-close {
                    right: 2em !important;
                    top: 2em !important;
                }
                &-comment {
                    &__body {

                    }
                }

                &-snapshots {
                    &__body {
                        > div {
                            > .all-snapshots {
                                > div {
                                    width: 100%;
                                    min-width: 100%;
                                    margin-right: 0 !important;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
</style>
