<template>
    <div class="loader-modal">
        <div class="loader-modal__animation"></div>
    </div>
</template>

<script>
    export default {
        name: "loaderModal"
    }
</script>

<style lang="scss">
.loader-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999999999999999999;
    &__animation {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        border: 3px dashed rgba(68, 95, 211, 0.6);
        animation: animationModalLoader .3s ease-in-out infinite;
    }
    @keyframes animationModalLoader {
        0%{transform: rotate(0deg)}
        100%{transform: rotate(360deg)}
    }
}
</style>