import Vue from "vue";
import hmModalTask from "./components/modalView/hm-modal-task"
import hmModalEditing from "./components/modalEditing/modalEditing"

window.hmModalTask = hmModalTask;
window.hmModalEditing = hmModalEditing;
// Vue.config.productionTip = false;
// window.hmDuelCatalog = hmDuelCatalog;
// /*new Vue({
//   components: {
//     hmDuelCatalog
//   }
// }).$mount("#hmDuelCatalog");*/
